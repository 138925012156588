<template>
  <div id="app">

<!--    <Login />-->
    <img
        src="https://www.fliesen-benker.de/wp-content/uploads/2020/06/Logo_final_CMYK.svg"
        alt="ERP"
        width="100%"
        v-if="$route.meta.focusMode"
    >
    <router-link class="navbar-item is-tab" to="/stockmobile" exact v-if="$route.meta.focusMode && $route.path != '/stockmobile'"><b-button icon-pack="fa" icon-left="tachometer-alt" expanded>Lager Mobil</b-button> </router-link>
    <div :hidden="$route.meta.focusMode">
    <b-navbar>
        <template slot="brand">
            <b-navbar-item href="/">
                <img
                    src="https://www.fliesen-benker.de/wp-content/uploads/2020/06/Logo_final_CMYK.svg"
                    alt="ERP"
                    width="110"
                >
            </b-navbar-item>
        </template>
        <template slot="start">
          <router-link class="navbar-item is-tab" to="/" exact><i class="fa fa-tachometer-alt"></i>&nbsp;Dashboard</router-link>

          <b-navbar-dropdown aria-role="menu">
            <template slot="label">
              <span><i class="fa fa-users"></i>&nbsp;Kontakte&nbsp;</span>
            </template>
            <b-navbar-item tag="router-link" :to="{ path: '/customers' }">
              <i class="fa fa-user"></i>
              Kunden
            </b-navbar-item>

            <b-navbar-item tag="router-link" :to="{ path: '/suppliers' }">
              <i class="fa fa-truck"></i>
              Lieferanten
            </b-navbar-item>
          </b-navbar-dropdown>


          <router-link class="navbar-item is-tab" to="/projects"><i class="fa fa-home"></i>&nbsp;Projekte</router-link>

          <b-navbar-dropdown aria-role="menu" :disabled="isLimitedUser">
            <template slot="label">
              <span><i class="fa fa-file-alt"></i>&nbsp;Dokumente&nbsp;</span>
            </template>
            <b-navbar-item tag="router-link" :to="{ path: '/documents/quote' }">
              <i class="fa fa-hand-holding-usd"></i>
              Angebote
            </b-navbar-item>

            <b-navbar-item tag="router-link" :to="{ path: '/documents/order' }">
              <i class="fa fa-handshake"></i>
              Aufträge
            </b-navbar-item>

<!--            <b-navbar-item tag="router-link" :to="{ path: '/documents/pickingnote' }">-->
<!--              <i class="fa fa-box-open"></i>-->
<!--              Kommissionierscheine-->
<!--            </b-navbar-item>-->

            <b-navbar-item tag="router-link" :to="{ path: '/documents/deliverynote' }">
              <i class="fa fa-truck"></i>
              Lieferscheine
            </b-navbar-item>

            <b-navbar-item tag="router-link" :to="{ path: '/documents/invoice' }">
              <i class="fa fa-euro-sign"></i>
              Rechnungen
            </b-navbar-item>

            <b-navbar-item tag="router-link" :to="{ path: '/documents/creditnote' }">
              <i class="fa fa-plus-square"></i>
              Gutschriften
            </b-navbar-item>
          </b-navbar-dropdown>


          <router-link class="navbar-item is-tab" to="/articles"><i class="fa fa-dolly"></i>&nbsp;Artikel</router-link>

          <router-link class="navbar-item is-tab" to="/services"><i class="fa fa-people-carry"></i>&nbsp;Dienstleistungen</router-link>
<!--          <router-link class="navbar-item is-tab" to="/stock"><i class="fa fa-pallet"></i>&nbsp;Lager</router-link>-->


          <b-navbar-dropdown aria-role="menu">
            <template slot="label">
              <span><i class="fa fa-pallet"></i>&nbsp;Lager</span>
            </template>
            <b-navbar-item tag="router-link" :to="{ path: '/stock' }">
              <i class="fa fa-warehouse"></i>
              Übersicht
            </b-navbar-item>

            <b-navbar-item tag="router-link" :to="{ path: '/stock/taking' }">
              <i class="fa fa-pen-fancy"></i>
              Inventur
            </b-navbar-item>

            <b-navbar-item tag="router-link" :to="{ path: '/stock/log' }">
              <i class="fa fa-history"></i>
              Buchungen
            </b-navbar-item>

            <b-navbar-item tag="router-link" :to="{ path: '/stock/value' }">
              <i class="fa fa-cash-register"></i>
              Bestandswert
            </b-navbar-item>

            <b-navbar-item tag="router-link" :to="{ path: '/stock/logold' }">
              <i class="fa fa-history"></i>
              Buchungen (alt)
            </b-navbar-item>








            <!--            <b-navbar-item tag="router-link" :to="{ path: '/articlecategories' }">-->
<!--              <i class="fa fa-tag"></i>-->
<!--              Artikelgruppen-->
<!--            </b-navbar-item>-->

<!--            <b-navbar-item tag="router-link" :to="{ path: '/paymentconditions' }">-->
<!--              <i class="fa fa-calendar"></i>-->
<!--              Zahlungsbedingungen-->
<!--            </b-navbar-item>-->

<!--            <b-navbar-item tag="router-link" :to="{ path: '/boilerplates' }">-->
<!--              <i class="fa fa-align-left"></i>-->
<!--              Textbausteine-->
<!--            </b-navbar-item>-->

<!--            <b-navbar-item tag="router-link" :to="{ path: '/labels' }">-->
<!--              <i class="fa fa-tag"></i>-->
<!--              Labels-->
<!--            </b-navbar-item>-->

<!--            <b-navbar-item tag="router-link" :to="{ path: '/orderout' }">-->
<!--              <i class="fa fa-box"></i>-->
<!--              Bestellungen-->
<!--            </b-navbar-item>-->

          </b-navbar-dropdown>




          <b-navbar-dropdown aria-role="menu">
            <template slot="label">
              <span><i class="fa fa-plus"></i>&nbsp;Sonstiges&nbsp;</span>
            </template>
            <b-navbar-item tag="router-link" :to="{ path: '/units' }">
              <i class="fa fa-ruler"></i>
              Einheiten
            </b-navbar-item>

            <b-navbar-item tag="router-link" :to="{ path: '/articlecategories' }">
              <i class="fa fa-tag"></i>
              Artikelgruppen
            </b-navbar-item>

            <b-navbar-item tag="router-link" :to="{ path: '/paymentconditions' }">
              <i class="fa fa-calendar"></i>
              Zahlungsbedingungen
            </b-navbar-item>

            <b-navbar-item tag="router-link" :to="{ path: '/boilerplates' }">
              <i class="fa fa-align-left"></i>
              Textbausteine
            </b-navbar-item>

            <b-navbar-item tag="router-link" :to="{ path: '/labels' }">
              <i class="fa fa-tag"></i>
              Labels
            </b-navbar-item>

            <b-navbar-item tag="router-link" :to="{ path: '/orderout' }">
              <i class="fa fa-box"></i>
              Bestellungen
            </b-navbar-item>

          </b-navbar-dropdown>

<!--            <router-link class="navbar-item is-tab" to="/statistics"><i class="fa fa-chart-line"></i>&nbsp;Statistiken</router-link>-->


        </template>

      <template #end>
        <b-navbar-item tag="div">
          <div class="buttons">
<!--            <a class="button is-primary">-->
<!--              <strong>Sign up</strong>-->
<!--            </a>-->
            <a class="button is-light" @click="logout()">
              <i class="fa fa-sign-out-alt"></i> &nbsp;Logout
            </a>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>

    </div>


    <!--<Invoices />-->
    <section class="section">
      <div class="container"><router-view :key='$route.fullPath'></router-view></div>
    </section>


  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import Dashboard from './views/Dashboard.vue'
// import Invoices from './views/Invoices.vue'
// import Invoice from './views/Invoice.vue'

//import Login from "@/components/Login";

import apiservice from "@/services/apiservice";
import { DialogProgrammatic as Dialog } from 'buefy'

export default {
  name: 'app',
  components: {
    //HelloWorld, Dashboard, Invoices, Invoice,
    //Login
  },
  computed: {
    isLoggedIn() {
      return JSON.parse(localStorage.getItem('user')) !== null
    },
    isLimitedUser(){
      let username = localStorage.getItem('username');
      return (username == "s.buetterhoff");

    }
  },
  methods: {
    logout: function () {
      apiservice().get("auth/logout")
      localStorage.clear()
      Dialog.alert({
        title: 'Logout',
        message: 'Sie wurden ausgeloggt. Wenn Sie die Anwendung weiter verwenden möchten, müssen Sie sich erneut einloggen.',
        type: 'is-success',
        hasIcon: true,
        icon: 'sign-out-alt',
        iconPack: 'fa',
        ariaRole: 'infodialog',
        ariaModal: true
      });
      this.$router.push({ path: `/login/` })
    }
  }
}
</script>

<style>
  
  @import url('https://use.fontawesome.com/releases/v5.11.1/css/all.css');
  /*@import url('https://use.fontawesome.com/releases/v6.5.1/css/all.css');*/
  @import url("https://cdn.jsdelivr.net/npm/@mdi/font@5.8.55/css/materialdesignicons.min.css");
  /*@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css');*/
  /*@import url('https://cdn.jsdelivr.net/npm/material-design-icons@3.0.1/index.min.js');*/



</style>
