<template>
  <div class="invoices">
    <section>
      <b-field group-multiline grouped>
        <div class="control">
<!--          <router-link class="" :to="'/documents/' + document_type + '/new'">-->
            <b-button type="is-primary" @click="newDocument"><i class="fa fa-plus"></i>&nbsp;{{documentTypeReadable}} erstellen</b-button>
<!--          </router-link>-->
        </div>

        <div class="control">

          <b-button type="is-primary" @click="fetchData"><i class="fa fa-recycle"></i>&nbsp;Aktualisieren</b-button>

        </div>
        <!--<b-select v-model="defaultSortDirection">
            <option value="asc">Default sort direction: ASC</option>
            <option value="desc">Default sort direction: DESC</option>
        </b-select>-->
        <b-select v-model="perPage" :disabled="!isPaginated">
          <option value="10">10 je Seite</option>
          <option value="20">20 je Seite</option>
          <option value="50">50 je Seite</option>
        </b-select>

      </b-field>

      <b-table
          :data="data"
          ref="table"
          :paginated="isPaginated"
          :per-page="perPage"
          :current-page.sync="currentPage"
          :pagination-simple="isPaginationSimple"
          :pagination-position="paginationPosition"
          :default-sort-direction="defaultSortDirection"
          :sort-icon="sortIcon"
          :sort-icon-size="sortIconSize"
          default-sort="user.first_name"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page">

        <template>
          <b-table-column field="document_nr" label="Nr." width="40" sortable searchable :custom-search="idsearch" v-slot="props">
            <b-tag class="is-primary is-light" v-if="!props.row.finalized">Entwurf</b-tag>
<!--            <span v-if="props.row.finalized">{{ props.row.document_nr }}</span>-->
            <span v-if="props.row.finalized && document_type == 'quote'">
              <b-tooltip label="Status: Offen"><b-tag class="is-warning is-medium" v-if="props.row.status == 'open'">{{props.row.document_nr }}</b-tag></b-tooltip>
              <b-tooltip label="Status: Gewonnen"><b-tag class="is-success is-medium" v-if="props.row.status == 'won'">{{props.row.document_nr }}</b-tag></b-tooltip>
              <b-tooltip label="Status: Verloren"><b-tag class="is-danger is-medium" v-if="props.row.status == 'lost'">{{props.row.document_nr }}</b-tag></b-tooltip>
              <b-tooltip label="Status: Durch anderes Angebot ersetzt"><b-tag class="is-light is-medium" v-if="props.row.status == 'replaced'">{{props.row.document_nr }}</b-tag></b-tooltip>
            </span>
            <span v-if="props.row.finalized && document_type == 'order'">
              <b-tooltip label="Status: Offen"><b-tag class="is-danger is-medium" v-if="props.row.status == 'open'">{{props.row.document_nr }}</b-tag></b-tooltip>
              <b-tooltip label="Status: Bestellt"><b-tag class="is-info is-medium" v-if="props.row.status == 'ordered'">{{props.row.document_nr }}</b-tag></b-tooltip>
              <b-tooltip label="Status: In Ausführung"><b-tag class="is-warning is-medium" v-if="props.row.status == 'inprogress'">{{props.row.document_nr }}</b-tag></b-tooltip>
              <b-tooltip label="Status: Erledigt"><b-tag class="is-success is-medium" v-if="props.row.status == 'done'">{{props.row.document_nr }}</b-tag></b-tooltip>
              <b-tooltip label="Status: Storniert"><b-tag class="is-dark is-medium" v-if="props.row.status == 'cancelled'">{{props.row.document_nr }}</b-tag></b-tooltip>
            </span>
            <span v-if="props.row.finalized && document_type == 'deliverynote'">
              <b-tooltip label="Status: Offen"><b-tag class="is-danger is-medium" v-if="props.row.status == 'open'">{{props.row.document_nr }}</b-tag></b-tooltip>
              <b-tooltip label="Status: Teillieferung"><b-tag class="is-info is-medium" v-if="props.row.status == 'partly'">{{props.row.document_nr }}</b-tag></b-tooltip>
              <b-tooltip label="Status: Geliefert"><b-tag class="is-warning is-medium" v-if="props.row.status == 'delivered'">{{props.row.document_nr }}</b-tag></b-tooltip>
              <b-tooltip label="Status: Fakturiert"><b-tag class="is-success is-medium" v-if="props.row.status == 'invoiced'">{{props.row.document_nr }}</b-tag></b-tooltip>
            </span>
            <span v-if="props.row.finalized && document_type == 'invoice'">
              <b-tooltip label="Status: Offen"><b-tag class="is-danger is-medium" v-if="props.row.status == 'open'">{{props.row.document_nr }}</b-tag></b-tooltip>
              <b-tooltip label="Status: Versendet"><b-tag class="is-warning is-medium" v-if="props.row.status == 'sent'">{{props.row.document_nr }}</b-tag></b-tooltip>
              <b-tooltip label="Status: Bezahlt"><b-tag class="is-success is-medium" v-if="props.row.status == 'paid'">{{props.row.document_nr }}</b-tag></b-tooltip>
            </span>
            <span v-if="props.row.finalized && document_type == 'creditnote'">
              <b-tooltip label="Status: Offen"><b-tag class="is-danger is-medium" v-if="props.row.status == 'open'">{{props.row.document_nr }}</b-tag></b-tooltip>
              <b-tooltip label="Status: Versendet"><b-tag class="is-warning is-medium" v-if="props.row.status == 'sent'">{{props.row.document_nr }}</b-tag></b-tooltip>
              <b-tooltip label="Status: Bezahlt"><b-tag class="is-success is-medium" v-if="props.row.status == 'paid'">{{props.row.document_nr }}</b-tag></b-tooltip>
            </span>

          </b-table-column>

          <b-table-column field="customer.name" label="Kunde (Projekt)" sortable :custom-search="searchCustomerProject" searchable v-slot="props">
            {{ props.row.customer.name }}<br />
            <b-tag v-if="props.row.project_id">{{ props.row.project.name }}, {{ props.row.project.zip }} {{ props.row.project.place }}</b-tag>
          </b-table-column>

          <!--<b-table-column field="project" label="Last Name" sortable>

          </b-table-column>-->

          <b-table-column field="invoice_type" label="Typ" sortable v-slot="props" v-if="document_type == 'invoice'">
            <b-tag class="is-success" v-if="props.row.invoice_type == 'final'">Schlussrechnung</b-tag>
            <b-tag class="is-primary" v-if="props.row.invoice_type == 'single'">Einzelrechnung</b-tag>
            <b-tag class="is-warning" v-if="props.row.invoice_type == 'interim'">Abschlagsrechnung</b-tag>
          </b-table-column>

          <b-table-column field="date" label="Datum" sortable centered v-slot="props">

            {{ new Date(props.row.date).toLocaleDateString() }}

          </b-table-column>

          <b-table-column label="Betrag" field="total" sortable v-slot="props" v-if="document_type != 'pickingnote'">
            <span v-if="props.row.total_net>0">{{ formatCurrency(props.row.total_net) }}<br /></span>
            <b>{{ formatCurrency(props.row.total) }}</b>
          </b-table-column>

          <b-table-column field="type" label="Status" sortable v-slot="props" v-if="document_type == 'pickingnote'">
            <b-tag class="is-primary is-light is-medium" v-if="!props.row.finalized && !props.row.finalized_picking">offen</b-tag>
            <b-tag class="is-warning is-medium" v-if="props.row.finalized && !props.row.finalized_picking">in Kommissionierung</b-tag>
            <b-tag class="is-success is-medium" v-if="props.row.finalized && props.row.finalized_picking">Kommissioniert</b-tag>
          </b-table-column>

          <b-table-column label="Aktionen" v-slot="props">
<!--            <b-button type="is-light">Stornieren</b-button>-->
<!--            <router-link class="" to="/invoice"><b-button type="is-primary" @click="openInvoice(props.row.id)"></b-button> </router-link>-->
            <b-field group-multiline grouped style="white-space: nowrap;">
              <div class="control" v-if="props.row.finalized">
                <b-tooltip label="Dokument ansehen" position="is-left">
                  <b-button type="is-primary is" @click="openItem(props.row.id)"><i class="fa fa-eye"></i></b-button>
                </b-tooltip>
              </div>
              <div class="control" v-if="!props.row.finalized">
                <b-tooltip label="Dokument bearbeiten" position="is-left">
                  <b-button type="is-primary" @click="openItem(props.row.id)"><i class="fa fa-pen"></i></b-button>
                </b-tooltip>
              </div>
              <div class="control">
                <b-tooltip label="In neues Dokument übernehmen" position="is-top">
                  <b-dropdown aria-role="list">
                    <template #trigger="">
                      <b-button type="is-primary is-light"><i class="fa fa-file-export"></i></b-button>
                    </template>

                    <b-dropdown-item aria-role="listitem" @click="copyToDocument(props.row.id, 'quote')"> <i class="fa fa-hand-holding-usd"></i>Angebot</b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" @click="copyToDocument(props.row.id, 'order')"><i class="fa fa-handshake"></i> Auftrag</b-dropdown-item>
<!--                    <b-dropdown-item aria-role="listitem" @click="copyToDocument(props.row.id, 'pickingnote')"><i class="fa fa-box-open"></i> Kommissionierschein</b-dropdown-item>-->
                    <b-dropdown-item aria-role="listitem" @click="copyToDocument(props.row.id, 'deliverynote')"><i class="fa fa-truck"></i> Lieferschein</b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" @click="copyToDocument(props.row.id, 'invoice')"><i class="fa fa-euro-sign"></i> Rechnung</b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" @click="copyToDocument(props.row.id, 'creditnote')"><i class="fa fa-plus-square"></i> Gutschrift</b-dropdown-item>
                  </b-dropdown>

<!--                  <b-button type="is-primary is-light" @click="openItem(props.row.id)"></b-button>-->
                </b-tooltip>
              </div>


              <div class="control" v-if="props.row.finalized">
                <b-tooltip label="Status ändern" position="is-top">
                  <b-dropdown aria-role="list">
                    <template #trigger="">
                      <b-button type="is-warning is-light"><i class="fa fa-spinner"></i></b-button>
                    </template>
                    <span v-if="document_type=='quote'">
                      <b-dropdown-item :disabled="props.row.status=='open'" aria-role="listitem" @click="updateStatus(props.row.id, 'open')"><b-tag class="is-warning is-medium">Offen</b-tag></b-dropdown-item>
                      <b-dropdown-item :disabled="props.row.status=='won'" aria-role="listitem" @click="updateStatus(props.row.id, 'won')"><b-tag class="is-success is-medium">Gewonnen</b-tag></b-dropdown-item>
                      <b-dropdown-item :disabled="props.row.status=='lost'" aria-role="listitem" @click="updateStatus(props.row.id, 'lost')"><b-tag class="is-danger is-medium">Verloren</b-tag></b-dropdown-item>
                      <b-dropdown-item :disabled="props.row.status=='replaced'" aria-role="listitem" @click="updateStatus(props.row.id, 'replaced')"><b-tag class="is-light is-medium">Ersetzt</b-tag></b-dropdown-item>
                    </span>
                    <span v-if="document_type=='order'">
                      <b-dropdown-item :disabled="props.row.status=='open'" aria-role="listitem" @click="updateStatus(props.row.id, 'open')"><b-tag class="is-danger is-medium">Offen</b-tag></b-dropdown-item>
                      <b-dropdown-item :disabled="props.row.status=='ordered'" aria-role="listitem" @click="updateStatus(props.row.id, 'ordered')"><b-tag class="is-info is-medium">Bestellt</b-tag></b-dropdown-item>
                      <b-dropdown-item :disabled="props.row.status=='inprogress'" aria-role="listitem" @click="updateStatus(props.row.id, 'inprogress')"><b-tag class="is-warning is-medium">In Bearbeitung</b-tag></b-dropdown-item>
                      <b-dropdown-item :disabled="props.row.status=='done'" aria-role="listitem" @click="updateStatus(props.row.id, 'done')"><b-tag class="is-success is-medium">Erledigt</b-tag></b-dropdown-item>
                      <b-dropdown-item :disabled="props.row.status=='cancelled'" aria-role="listitem" @click="updateStatus(props.row.id, 'cancelled')"><b-tag class="is-dark is-medium">Storniert</b-tag></b-dropdown-item>
                    </span>
                    <span v-if="document_type=='invoice'">
                      <b-dropdown-item :disabledf="props.row.status=='open'" aria-role="listitem" @click="updateStatus(props.row.id, 'open')"><b-tag class="is-danger is-medium">Offen</b-tag></b-dropdown-item>
                      <b-dropdown-item :disabled="props.row.status=='sent'" aria-role="listitem" @click="updateStatus(props.row.id, 'sent')"><b-tag class="is-warning is-medium">Versendet</b-tag></b-dropdown-item>
                      <b-dropdown-item :disabled="props.row.status=='paid'" aria-role="listitem" @click="updateStatus(props.row.id, 'paid')"><b-tag class="is-success is-medium">Bezahlt</b-tag></b-dropdown-item>
                    </span>
                    <span v-if="document_type=='creditnote'">
                      <b-dropdown-item :disabled="props.row.status=='open'" aria-role="listitem" @click="updateStatus(props.row.id, 'open')"><b-tag class="is-danger is-medium">Offen</b-tag></b-dropdown-item>
                      <b-dropdown-item :disabled="props.row.status=='sent'" aria-role="listitem" @click="updateStatus(props.row.id, 'sent')"><b-tag class="is-warning is-medium">Versendet</b-tag></b-dropdown-item>
                      <b-dropdown-item :disabled="props.row.status=='paid'" aria-role="listitem" @click="updateStatus(props.row.id, 'paid')"><b-tag class="is-success is-medium">Bezahlt</b-tag></b-dropdown-item>
                    </span>
                    <span v-if="document_type=='deliverynote'">
                      <b-dropdown-item :disabled="props.row.status=='open'" aria-role="listitem" @click="updateStatus(props.row.id, 'open')"><b-tag class="is-danger is-medium">Offen</b-tag></b-dropdown-item>
                      <b-dropdown-item :disabled="props.row.status=='partly'" aria-role="listitem" @click="updateStatus(props.row.id, 'partly')"><b-tag class="is-info is-medium">Teillieferung</b-tag></b-dropdown-item>
                      <b-dropdown-item :disabled="props.row.status=='delivered'" aria-role="listitem" @click="updateStatus(props.row.id, 'delivered')"><b-tag class="is-warning is-medium">Geliefert</b-tag></b-dropdown-item>
                      <b-dropdown-item :disabled="props.row.status=='invoiced'" aria-role="listitem" @click="updateStatus(props.row.id, 'invoiced')"><b-tag class="is-success is-medium">Fakturiert</b-tag></b-dropdown-item>
                    </span>

                  </b-dropdown>

                  <!--                  <b-button type="is-primary is-light" @click="openItem(props.row.id)"></b-button>-->
                </b-tooltip>
              </div>


              <div class="control" v-if="!props.row.finalized">
                <b-tooltip label="Dokument löschen" position="is-right">
                  <b-button type="is-danger is-light" @click="deleteItem(props.row.id)"><i class="fa fa-trash"></i></b-button>
                </b-tooltip>
              </div>
            </b-field>


<!--            <router-link v-if="!props.row.finalized" class="" to="/invoice"><b-button type="is-primary" @click="openInvoice(props.row.id)"></b-button> </router-link>-->


          </b-table-column>
        </template>
      </b-table>
    </section>
    <b-loading :active="isLoading" :is-full-page="false" :can-cancel="false" />
  </div>
</template>

<script>

import apiservice from "@/services/apiservice";
import CustomerPickerPromt from "@/components/CustomerPickerPromt";
// import {DialogProgrammatic as Dialog, ToastProgrammatic as Toast} from "buefy/types/components";


export default {
  name: 'DocumentOverview',
  props: {
    msg: String,
    document_type: String
  },
  components: {
    // ArticleCategory
  },
  data() {
    return {
      data: [],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 10
    }
  },
  methods: {
    fetchData: function() {
      if (this.isLimitedUser()){
        return;
      }
      this.isLoading = true;
      apiservice()
          .get('documents/' + this.document_type)
          .then(response => {
            this.data = response.data.data
            this.isLoading = false;
          });
    },
    // eslint-disable-next-line no-unused-vars
    openItem: function(id){
      this.$router.push({ path: `/documents/` + this.document_type + `/${id}` })
    },
    formatCurrency: function (value) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)
    },
    newDocument: function(){

      // let s = this;
      this.$buefy.modal.open({
        component: CustomerPickerPromt,
        parent: this,
        props: {
          // "question": question
        },
        onCancel: function () {
          console.log()
        },
        events: {
          'confirm': value => {
            let newData = {id:'new',customer_id:value.id};
            let self = this;
            apiservice().post('documents/'+ this.document_type + '/create', newData)
                .then(function (response) {

                  self.$buefy.toast.open({
                    message: 'Neues Dokument erstellt',
                    position: 'is-bottom',
                    type: 'is-success'
                  });
                  console.log(response);

                  self.$router.push("/documents/" + self.document_type + "/" + response.data.data.id);
                })
                .catch(function (error) {
                  self.$buefy.dialog.alert({
                    title: 'Fehler',
                    message: 'Ein Fehler ist aufgetreten: ' + error,
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                  })
                  console.log(error);
                });
          }

        }
      })



    },
    copyToDocument: function(id, target_document_type) {

      let newData = {id: id};
      let self = this;
      apiservice().post('documents/' + this.document_type + '/copyto/' + target_document_type, newData)
          .then(function (response) {


            self.$buefy.toast.open({
              message: 'Neues Dokument erstellt',
              position: 'is-bottom',
              type: 'is-success'
            });
            console.log(response);

            self.$router.push("/documents/" + target_document_type + "/" + response.data.data.id);
          })
          .catch(function (error) {
            self.$buefy.dialog.alert({
              title: 'Fehler',
              message: 'Ein Fehler ist aufgetreten: ' + error,
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            console.log(error);
          });

    },
    updateStatus: function(id, newStatus) {
      this.isLoading = true;

      let newData = {id: id, status: newStatus};
      let self = this;
      apiservice().post('documents/' + this.document_type + '/update/status', newData)
          .then(function (response) {

            self.$buefy.toast.open({
              message: 'Status angepasst',
              position: 'is-bottom'
              // type: 'is-success'
            });

            self.data.forEach(function(row) {
              if (row.id == id){
                row.status = response.data.data.status;
              }
            });

            self.isLoading = false;

            console.log(response);
          })
          .catch(function (error) {
            self.$buefy.dialog.alert({
              title: 'Fehler',
              message: 'Ein Fehler ist aufgetreten: ' + error,
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            console.log(error);
          });

    },
    deleteItem: function(id) {
      let self = this;

      this.$buefy.dialog.confirm({
        title: 'Dokument löschen?',
        message: 'Sind Sie sicher, dass Sie das Dokument löschen möchten?',
        confirmText: 'Ja, löschen',
        cancelText: 'Nein',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          apiservice().delete('documents/' + this.document_type + '/' + id)
              .then(function (response) {

                self.$buefy.toast.open({
                  message: 'Dokument gelöscht',
                  position: 'is-bottom',
                  type: 'is-success'
                });
                console.log(response);
                self.fetchData()
              })
              .catch(function (error) {
                self.$buefy.dialog.alert({
                  title: 'Fehler',
                  message: 'Ein Fehler ist aufgetreten: ' + error,
                  type: 'is-danger',
                  hasIcon: true,
                  icon: 'times-circle',
                  iconPack: 'fa',
                  ariaRole: 'alertdialog',
                  ariaModal: true
                })
                console.log(error);
              });
        }
      })
    },
    idsearch: function(d, input) {
      if (!d.document_nr) {
        return false
      }
      return d.document_nr.toString().toLowerCase().includes(input.toLowerCase())
    },
    searchCustomerProject: function(d, input) {
      let s = d.customer.name;
      if (d.project)
        s += d.project.name;
      return s.toLowerCase().includes(input.toLowerCase())
    },
    isLimitedUser(){
      let username = localStorage.getItem('username');
      return (username == "s.buetterhoff");

    }
  },
  computed: {
    documentTypeReadable() {
      return this.document_type == 'quote' ? "Angebot" :
          this.document_type == 'order' ? "Auftrag" :
              this.document_type == 'deliverynote' ? "Lieferschein" :
                  this.document_type == 'pickingnote' ? "Kommissionierschein" :
                      this.document_type == 'creditnote' ? "Gutschrift" :
                        this.document_type == 'invoice' ? "Rechnung" : this.document_type;
    }
  },
  beforeMount(){
    this.fetchData()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
